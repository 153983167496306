import snakecaseKeys from 'snakecase-keys'
import { cleanNullish } from '@sevenrooms/core/utils'
import type { BulkUpdateSettingsRequest as BulkUpdateSettingsRequestApi } from '../../api'
import type { BulkUpdateSettingsRequest } from '../../domain'

export namespace SaveBulkUpdateSettingsAdapter {
  export function toServer(data: BulkUpdateSettingsRequest): BulkUpdateSettingsRequestApi {
    return cleanNullish(snakecaseKeys(data))
  }
}
